/* You can add global styles to this file, and also import other style files */

@import '~@angular/material/theming';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

:root {
  --cf-primary1: #004bff;
  --cf-primary2: #0077e7;
  --cf-primary3: #00c3ff;
  --cf-primary4: #00e7e7;
  --cf-primary5: #00feb7;
  --cf-status-red: #f44336;
  --cf-status-amber: #ff9800;
  --cf-status-green: #4caf50;
  --cf-secondary-blue1: #4b7894;
  --cf-secondary-blue2: #5588a8;
  --cf-secondary-blue3: #7ab7d3;
  --cf-secondary-blue4: #a9dff2;
  --cf-secondary-blue5: #daf4fa;
  --cf-secondary-grey1: #6c6c6c;
  --cf-secondary-grey2: #909090;
  --cf-secondary-grey3: #b1b1b1;
  --cf-secondary-grey4: #d3d3d3;
  --cf-secondary-grey5: #ebebeb;
  --cf-secondary-grey6: #f5f5f5;
  --cf-secondary-grey7: #fafafa;
  --cf-button-outline-grey: #707070;
  --cf-button-outline-teal: #00e7e7;
  --cf-nav-bar-blue1: #137ec7;
  --cf-nav-bar-blue2: #3683e3;
  --cf-nav-bar-blue3: #44aeef;
  --cf-active-freight-blue1: #1b203c;
  --cf-sea-port: #62b3a6;
  --cf-sea-port-light: #92e3d6;
  --cf-air-port: #228386;
  --cf-air-port-light: #52b3b6;
  --cf-white: #ffffff;
  --cf-black: #000000;
  --cf-font-black: #26282d;
  --default-background-color: #eee;
  --default-dark-grey: #373d3f;
  --cf-light-blue: #078cff;
  --cf-light-blue-2: #046cff;
  --cf-blue: #004bff;
  --cf-dark-blue: #0031ad;
  --cf-dark-blue-2: #012172;
  --cf-black-blue: #001038;
  --cf-dark-orange-brown: #400f03;
  --cf-orange-brown: #801f06;
  --cf-yellow-orange: #ff7d00;
  --cf-orange: #e85000;
  --cf-hot-orange: #ff3f0d;
  --cf-material-blue: #3f51b5;
  --cf-font-blue1: #00adef;

  --cf-border-gray1: rgba(47, 46, 46, 0.21);

  --site-width1: 980px;
  --site-width2: 740px;
}

$cf-status-red: var(--cf-status-red);
$cf-status-amber: var(--cf-status-amber);
$cf-status-green: var(--cf-status-green);
$cf-font-blue1: var(--cf-font-blue1);

$cf_font_aee5298e_woff2: './assets/fonts/font-aee5298e/file.woff2';
$cf_font_aee5298e_woff: './assets/fonts/font-aee5298e/file.woff';
$cf_font_aee5298e_ttf: './assets/fonts/font-aee5298e/file.ttf';

$cf_font_333663d4_woff2: './assets/fonts/font-333663d4/file.woff2';
$cf_font_333663d4_woff: './assets/fonts/font-333663d4/file.woff';
$cf_font_333663d4_ttf: './assets/fonts/font-333663d4/file.ttf';

$cf_font_c4d208d0_woff2: './assets/fonts/font-c4d208d0/file.woff2';
$cf_font_c4d208d0_woff: './assets/fonts/font-c4d208d0/file.woff';
$cf_font_c4d208d0_ttf: './assets/fonts/font-c4d208d0/file.ttf';

@font-face {
  font-family: 'cf_font_aee5298e';
  src: url($cf_font_aee5298e_woff2) format('woff2'), url($cf_font_aee5298e_woff) format('woff'),
    url($cf_font_aee5298e_ttf) format('ttf');
}

@font-face {
  font-family: 'cf_font_333663d4';
  src: url($cf_font_333663d4_woff2) format('woff2'), url($cf_font_333663d4_woff) format('woff'),
    url($cf_font_333663d4_ttf) format('ttf');
}

@font-face {
  font-family: 'cf_font_cdaa4f93';
  src: url('./assets/fonts/font-cdaa4f93/file.woff2') format('woff2'),
    url('./assets/fonts/font-cdaa4f93/file.woff') format('woff'),
    url('./assets/fonts/font-cdaa4f93/file.ttf') format('ttf');
}

@font-face {
  font-family: 'cf_font_314c063d';
  src: url('./assets/fonts/font-314c063d/file.woff2') format('woff2'),
    url('./assets/fonts/font-314c063d/file.woff') format('woff'),
    url('./assets/fonts/font-314c063d/file.ttf') format('ttf');
}

@font-face {
  font-family: 'cf_font_1e635deb';
  src: url('./assets/fonts/font-1e635deb/file.woff2') format('woff2'),
    url('./assets/fonts/font-1e635deb/file.woff') format('woff'),
    url('./assets/fonts/font-1e635deb/file.ttf') format('ttf');
}

@font-face {
  font-family: 'cf_font_4ffb5e97';
  src: url('./assets/fonts/font-4ffb5e97/file.woff2') format('woff2'),
    url('./assets/fonts/font-4ffb5e97/file.woff') format('woff'),
    url('./assets/fonts/font-4ffb5e97/file.ttf') format('ttf');
}

@font-face {
  font-family: 'cf_font_c4d208d0';
  src: url($cf_font_c4d208d0_woff2) format('woff2'), url($cf_font_c4d208d0_woff) format('woff'),
    url($cf_font_c4d208d0_ttf) format('ttf');
}

.main-content {
  padding: 20px;
}

// toaster styles
.toast-success {
  background-color: $cf-status-green !important;
}
.toast-error {
  background-color: $cf-status-red !important;
}
.toast-info {
  background-color: var(--cf-primary2) !important;
}
.toast-error {
  background-color: $cf-status-amber !important;
}
